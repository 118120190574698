<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>企业管理2</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box" style="min-height:720px">
            <AppLink v-for="(item, key) of list" :key="key" :title="key" :list="item" :isTab="1" parentKey='enterprise' parentModule="enterprise"/>
        </div>
    </div>
</template>

<script>
    export default {
        name:'enterprise',
        data() {
            return {
                list:{
                    "企业管理":[
                        {name: "企业管理",desc: "企业管理",module_id: "enterprise"},
                        {name: "账户管理",desc: "账户管理",module_id: "enterpriseAccountLive"},
                        {name: "应用管理",desc: "应用管理",module_id: "enterpriseApplication"},
                        {name: "实况管理",desc: "实况管理",module_id: "enterpriseMonitor"},
                        {name: "权限管理",desc: "权限管理",module_id: "enterpriseAuth"},
                        {name: "企业统计",desc: "企业统计",module_id: "enterpriseStatistics"},
                        {name: "帮助中心",desc: "帮助中心",module_id: "helper"},
                        {name: "操作日志",desc: "操作日志",module_id: "logging"},
                        {name: "实验室",desc: "A.I",module_id: "lab"},
                        {name: "ACE",desc: "Stock",module_id: "ace"},
                        {name: "清理缓存",desc: "清理缓存",module_id: "clean",isPath:true},
                    ]
                }
            }
        },
        
    }
</script>

<style lang="scss">
    .appModule{
        position: relative;
        &-icon{
            position: absolute;
            right: 12px;
            top:0px;
        }
        &-card{
            background-color: #eee;
        }
        .ant-card-body{
            padding: 12px;
        }
    }
</style>